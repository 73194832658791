const scriptTag = document.getElementById('user-session-json');
const userSessionJsonString = (
  scriptTag
    .innerHTML
    .replace('//<![CDATA[', '').replace('//]]>', '')
    .replace(/=>/g, ':')
    .trim()
);

const { companyName, countryBounds } = JSON.parse(userSessionJsonString);

export { companyName, countryBounds };
