import { css } from '@emotion/css';

const mainStyles = css`
  margin: 20px;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
`;

const loadingContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
`;

const scrollableContainerStyles = css`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 10px;
  gap: 15px;
`;

const headerStyles = css`
  font-weight: bold;
  text-align: center;
  padding: 8px 0;
  background-color: #f5f5f5;
  border: 1px solid #d9d9d9;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  flex: 0 0 300px;
  white-space: nowrap;
`;

const tableStyles = css`
  flex: 0 0 300px;
  overflow: hidden;
`;

const filterContainerStyles = css`
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
`;

export {
  filterContainerStyles,
  mainStyles,
  scrollableContainerStyles,
  headerStyles,
  tableStyles,
  loadingContainer,
};
