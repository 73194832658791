import React from 'react';
import { arrayOf, number, string } from 'prop-types';
import { Typography, Row, Space, Button, Popconfirm, Descriptions, Image } from 'antd';
import Layout from 'components/Layout';
import { omit } from 'lodash';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { scoutingReportsPath, scoutingReportPath, editScoutingReportPath } from 'js-routes';
import { fetchData } from 'helpers/fetchData';
import { i18n } from 'helpers/i18n';

const removeReportRequest = (id) => fetchData({
  url: scoutingReportPath(id),
  method: 'DELETE',
}).then(() => { window.location.href = scoutingReportsPath(); });

const ScoutingReport = (props) => {
  const { id, photosUrls } = props;

  const itemsWithoutPhotos = Object.entries(omit(props, 'photosUrls')).map(([key, value]) => (
    { label: key, children: value }
  ));

  const itemsPhotos = {
    label: 'photos',
    children: (
      <Image.PreviewGroup>
        {photosUrls.map((photoUrl) => <Image key={photoUrl} width={200} src={photoUrl} />)}
      </Image.PreviewGroup>
    ),
  };

  return (
    <Layout>
      <Row justify="space-between" align="middle">
        <Typography.Title>{`${i18n.t('scouting_report.scouting_report')} #${id}`}</Typography.Title>

        <Space style={{ marginTop: 11 }}>
          <Button href={editScoutingReportPath(id)} icon={<EditOutlined />}>
            {i18n.t('edit')}
          </Button>
          <Popconfirm
            title={i18n.t('scouting_report.delete_popup_title')}
            description={i18n.t('scouting_report.delete_popup_description')}
            onConfirm={() => removeReportRequest(id)}
          >
            <Button type="primary" icon={<DeleteOutlined />} danger>
              {i18n.t('delete')}
            </Button>
          </Popconfirm>
        </Space>
      </Row>

      <Descriptions
        items={[...itemsWithoutPhotos, itemsPhotos]}
        column={1}
        contentStyle={{ display: 'block' }}
        bordered
      />
    </Layout>
  );
};

ScoutingReport.propTypes = {
  id: number.isRequired,
  photosUrls: arrayOf(string).isRequired,
};

ScoutingReport.defaultProps = {
};

export default ScoutingReport;
