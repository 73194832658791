import React, { useRef } from 'react';
import { Form, Typography } from 'antd';
import Layout from 'components/Layout';
import { i18n } from 'helpers/i18n';
import Map from 'components/Map';
import bbox from '@turf/bbox';

const QuarterRowForm = ({ quarterRow: { id, name }, geoJsonLayers }) => {
  const mapRef = useRef(null);
  const action = id ? i18n.t('edit') : i18n.t('create');
  const quarterRowName = name ? `«${name}»` : '';
  const { quarterRowsGeoJson } = geoJsonLayers;

  return (
    <Layout>
      <Typography.Title level={1}>
        {`${action} 
        ${i18n.t('activerecord.models.quarter_row').toLocaleLowerCase()} 
        ${quarterRowName}`}
      </Typography.Title>

      <Form layout="vertical">
        <Form.Item
          name="shape"
          label={i18n.t('activerecord.attributes.quarter_row.shape')}
          rules={[{ required: true }]}
        >
          <Map
            mapRef={mapRef}
            geoJsonLayers={geoJsonLayers}
            bounds={bbox(quarterRowsGeoJson)}
            isHoverEnabled={false}
            isClickEnabled={false}
          />
        </Form.Item>
      </Form>
    </Layout>
  );
};

export default QuarterRowForm;
