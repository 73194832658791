const getCsrfToken = () => document.getElementsByName('csrf-token')[0].getAttribute('content');

const buildHeaders = (headers, hasJsonContentType) => ({
  'X-CSRF-Token': getCsrfToken(),
  ...(hasJsonContentType && { 'Content-Type': 'application/json' }),
  ...headers,
});

const fetchData = ({ url, method, headers, body, contentType, responseType = 'json' }) => {
  const hasFormData = body instanceof FormData;
  const hasJsonContentType = contentType === 'json';
  const hasJsonResponseType = responseType === 'json';

  const response = fetch(url, {
    method,
    headers: buildHeaders(headers, hasJsonContentType),
    body: hasFormData ? body : JSON.stringify(body),
  });

  return hasJsonResponseType ? response.then((r) => r.json()) : response;
};

export { fetchData };
