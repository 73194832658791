import { css } from '@emotion/css';

const mapStyleSwitcherStyles = css`
  position: absolute;
  top: 44px;
  left: 10px;
  z-index: 1;
  background-color: #fff;
  border-radius: 10px;
  padding: 1px 8px;
`;

export { mapStyleSwitcherStyles };
