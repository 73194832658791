import PruningGarden from './pruning_garden.svg';
import GardenForming from './garden_forming.svg';
import RodentProtection from './rodent_protection.svg';
import EquipmentInstallation from './equipment_installation.svg';
import LoadingUnloadingWork from './loading_unloading_work.svg';
import GardenPlanting from './garden_planting.svg';
import GardenCare from './garden_care.svg';
import Harvesting from './harvesting.svg';

const workTypeNameToIconName = {
  pruningGarden: PruningGarden,
  gardenForming: GardenForming,
  rodentProtection: RodentProtection,
  equipmentInstallation: EquipmentInstallation,
  loadingUnloadingWork: LoadingUnloadingWork,
  gardenPlanting: GardenPlanting,
  gardenCare: GardenCare,
  harvesting: Harvesting,
};

export { workTypeNameToIconName };
