import { quartersPath, quarterRowsPath, plantsPath } from 'js-routes';
import { i18n } from 'helpers/i18n';

const [quarters, quarterRows, plants] = ['quarters', 'quarter_rows', 'plants'];

const shapesTypes = {
  [quarters]: {
    url: quartersPath(),
    label: i18n.t(quarters),
    value: quarters,
  },
  [quarterRows]: {
    url: quarterRowsPath(),
    label: i18n.t(quarterRows),
    value: quarterRows,
  },
  [plants]: {
    url: plantsPath(),
    label: i18n.t('planting_places'),
    value: plants,
  },
};

export { shapesTypes };
