import { css } from '@emotion/css';

const siderStyles = css`
  border-radius: 10px;
  margin-left: 5px;
  padding-inline: 20px;
  background-color: #fff !important;

  h2 {
    margin-top: 14px;

    sup {
      font-size: 14px;
      font-weight: 400;
      margin-left: 5px;
      color: grey;
    }
  }
`;

const tableStyles = css`
  th {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 60px;
  }

  .ant-table-body .ant-table-cell:first-child {
    padding: 0;
  }

  .ant-table-body .ant-table-cell:nth-child(2) {
    padding: 0;
  }
`;

const hoverableTableCell = css`
  padding: 16px;

  &:hover {
    cursor: pointer;
  }
`;

export { siderStyles, tableStyles, hoverableTableCell };
