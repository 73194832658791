import React, { useState } from 'react';
import { func } from 'prop-types';
import { Space, Upload, Button, Typography, Segmented, theme } from 'antd';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { fetchData } from 'helpers/fetchData';
import { shapefilesUploaderIndexPath } from 'js-routes';
import { i18n } from 'helpers/i18n';
import { shapesTypes } from './shapesTypes';

const { Paragraph } = Typography;

const FirstStep = ({ setGeojson, setShapesType }) => {
  const [filesToUpload, setFilesToUpload] = useState([]);
  const { token } = theme.useToken();

  const beforeUpload = (file) => {
    setFilesToUpload((files) => [...files, file]);
    return false; // To prevent upload
  };

  const uploadShapeFiles = () => {
    const formData = new FormData();
    filesToUpload.forEach((file) => formData.append('shapefiles[]', file));

    fetchData({
      url: shapefilesUploaderIndexPath(),
      method: 'POST',
      body: formData,
    }).then(setGeojson);
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Segmented
        options={Object.values(shapesTypes)}
        onChange={(shapeType) => setShapesType(shapeType)}
        block
      />

      <Upload.Dragger beforeUpload={beforeUpload} multiple>
        <InboxOutlined style={{ fontSize: 130, color: token.colorPrimary }} />

        <Paragraph style={{ fontSize: 18, marginTop: 19 }}>
          {i18n.t('shapefiles_uploader.dragger_text')}
        </Paragraph>
      </Upload.Dragger>

      <Button type="primary" onClick={uploadShapeFiles} style={{ width: '100%' }}>
        <UploadOutlined />
        {i18n.t('shapefiles_uploader.upload')}
      </Button>
    </Space>
  );
};

FirstStep.propTypes = {
  setGeojson: func.isRequired,
};

export default FirstStep;
