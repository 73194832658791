import { css } from '@emotion/css';

const draggerStyles = css`
  width: 100%;
  flex-direction: column;
  margin-bottom: 25px;

  .ant-space-item {
    width: 100%;
  }

  .ant-upload-drag {
    height: 350px;
  }
`;

export { draggerStyles };
