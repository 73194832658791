import circle from '@turf/circle';
import { featureCollection } from '@turf/helpers';
import { quarterPath, quarterRowPath, plantPath } from 'js-routes';

const LAYER_ID_TO_PATH_MAPPING = {
  quartersGeoJson: quarterPath,
  quarterRowsGeoJson: quarterRowPath,
  plantsGeoJson: plantPath,
};

const DEFAULT_FEATURE_COLORS = {
  quartersGeoJson: { color: '#4c1f14' },
  quarterRowsGeoJson: { color: '#369f4c' },
  plantsGeoJson: { color: '#4c1f14' },
};

const layerIds = Object.keys(LAYER_ID_TO_PATH_MAPPING);

const addSources = (map, sources) => {
  sources.forEach(({ id, source }) => {
    if (map.getSource(id) || !source.data) return;
    map.addSource(id, source);
  });
};

const addLayers = (map, layers) => {
  layers.forEach((layer) => {
    if (map.getLayer(layer.id) || !map.getSource(layer.source)) return;
    map.addLayer(layer);
  });
};

const addClickHandler = ({ map, isClickEnabled }) => {
  if (!isClickEnabled) return;

  layerIds.reverse().forEach((layerId) => {
    map.on('click', layerId, ({ features }) => {
      window.open(LAYER_ID_TO_PATH_MAPPING[layerId](features[0].id), '_blank');
    });
  });
};

const buildSources = (sourcesData) => (
  Object.entries(sourcesData).map(([id, data]) => ({ id, source: { type: 'geojson', data } }))
);

const colorizeFeatures = (features, map, mapColorType, { uncolorize = false } = {}) => {
  features.forEach((feature) => {
    const { id, properties } = feature;
    const { layerId } = properties;
    if (!id) return;

    const mapColorTypeObjects = (layerId === 'quartersGeoJson') ? [] : properties[mapColorType];
    const [{ color } = {}] = [...mapColorTypeObjects, DEFAULT_FEATURE_COLORS[layerId]];
    map.setFeatureState({ id, source: layerId }, { color, opacity: uncolorize ? 0 : 1 });
  });
};

const buildCircle = (feature) => {
  const { id, properties, geometry: { coordinates } } = feature;
  const { radius = 0.25 } = properties;

  return { id, ...circle(coordinates, radius, { properties, units: 'meters' }) };
};

const buildCircles = (plantsGeoJson) => {
  if (!plantsGeoJson) return null;

  const features = (
    plantsGeoJson.features
      ? plantsGeoJson.features.map(buildCircle)
      : [buildCircle(plantsGeoJson)]
  );

  return featureCollection(features);
};

export {
  addSources,
  addLayers,
  addClickHandler,
  buildSources,
  colorizeFeatures,
  buildCircles,
  layerIds,
};
