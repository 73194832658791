import React from 'react';
import { arrayOf, number } from 'prop-types';
import { Table, Col, Typography } from 'antd';
import { i18n } from 'helpers/i18n';
import { manualWorkTypeGroupPropTypes, manualWorkTypePropTypes } from '../propTypes';

const { Title } = Typography;

const buildColumns = () => [
  {
    title: i18n.t('manual_work_type_catalog.work_type_name'),
    dataIndex: 'humanName',
    width: '50%' },
];

const WorkTypeTable = ({ workTypeGroup, workTypes, activeWorkTypeGroupId, span }) => {
  if (activeWorkTypeGroupId !== workTypeGroup.id) return null;

  return (
    <Col key={workTypeGroup.id} span={span}>
      <Table
        columns={buildColumns()}
        dataSource={workTypes}
        title={() => <Title level={4} style={{ margin: 0 }}>{workTypeGroup.humanName}</Title>}
        pagination={false}
        rowKey="id"
      />
    </Col>
  );
};

WorkTypeTable.defaultProps = {
  activeWorkTypeGroupId: 0,
};

WorkTypeTable.propTypes = {
  workTypeGroup: manualWorkTypeGroupPropTypes.isRequired,
  workTypes: arrayOf(manualWorkTypePropTypes).isRequired,
  activeWorkTypeGroupId: number,
  span: number.isRequired,
};

export default WorkTypeTable;
