import React, { useState, useMemo } from 'react';
import { arrayOf, number, shape, string } from 'prop-types';
import {
  AutoComplete, Input, Row, Space, Typography, Table, Tag, Layout as AntdLayout,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { uniq, uniqBy, find, difference, isEmpty } from 'lodash';
import Layout from 'components/Layout';
import Map from 'components/Map';
import bbox from '@turf/bbox';
import { i18n } from 'helpers/i18n';
import {
  buildFeatureHighlighter, buildFeatureUnhighlighter,
} from 'components/Map/helpers/featureHighlight';
import { useRotateMap } from 'components/Map/hooks/useRotateMap';
import { useMapColorType } from 'components/Map/hooks/useMapColorType';
import { colorizeFeatures } from 'components/Map/helpers';
import { siderStyles, tableStyles, hoverableTableCell } from './styles';

const { Title } = Typography;
const { Content, Sider } = AntdLayout;

const filterValue = (value) => ({ text: value, value });
const stringSorterByAttribute = (attributeName) => (a, b) => (
  new Intl.Collator().compare(a[attributeName], b[attributeName])
);

const buildColumns = ({
  tableRows, highlightQuarter, highlightRow, unhighlightFeature, fitQuarterBounds, fitRowBounds,
}) => [
  {
    title: i18n.t('activerecord.models.quarter_row'),
    dataIndex: 'name',
    render: (name, { id }) => (
      <Row
        className={hoverableTableCell}
        onClick={() => fitRowBounds(id)}
        onMouseEnter={() => highlightRow(id)}
        onMouseLeave={unhighlightFeature}
      >
        {name}
      </Row>
    ),
  },
  {
    title: i18n.t('activerecord.models.quarter'),
    dataIndex: 'quarter',
    filters: uniq(tableRows.map(({ quarter }) => quarter)).map(filterValue),
    onFilter: (value, record) => record.quarter === value,
    render: (name, { quarterId }) => (
      <Row
        className={hoverableTableCell}
        onClick={() => fitQuarterBounds(quarterId)}
        onMouseEnter={() => highlightQuarter(quarterId)}
        onMouseLeave={unhighlightFeature}
      >
        {name}
      </Row>
    ),
  },
  {
    title: i18n.t('activerecord.models.cultivar'),
    dataIndex: 'cultivar',
    filters: (
      uniq(tableRows.flatMap(({ cultivar }) => cultivar.map(({ name }) => name))).map(filterValue)
    ),
    onFilter: (value, record) => record.cultivar.some(({ name }) => name === value),
    render: (cultivars) => (
      <Space direction="vertical">
        {cultivars.map(({ color, name }) => <Tag key={name} color={color}>{name}</Tag>)}
      </Space>
    ),
  },
  {
    title: i18n.t('activerecord.models.crop', { count: 1 }),
    dataIndex: 'crop',
    render: (crops) => (
      <Space direction="vertical">
        {crops.map(({ color, name }) => <Tag key={name} color={color}>{name}</Tag>)}
      </Space>
    ),
  },
  { title: i18n.t('activerecord.attributes.quarter_row.plants_count'), dataIndex: 'plantsCount' },
];

const getVisibleFeatures = ({ tableRows, quartersFeatures, rowsFeatures, plantsFeatures }) => {
  const visibleQuarterIds = tableRows.map(({ quarterId }) => quarterId);
  const visibleQuarterRowsIds = tableRows.map(({ id }) => id);
  const visibleQuartersFeatures = (
    quartersFeatures.filter(({ properties }) => visibleQuarterIds.includes(properties.id))
  );
  const visibleRowsFeatures = (
    rowsFeatures.filter(({ properties }) => visibleQuarterRowsIds.includes(properties.id))
  );

  return [...visibleQuartersFeatures, ...visibleRowsFeatures, ...plantsFeatures];
};

const Monitoring = ({ geoJsonLayers, tableRows, totalArea }) => {
  const { quartersGeoJson, quarterRowsGeoJson, plantsGeoJson } = geoJsonLayers;
  const { features: quartersFeatures = [] } = quartersGeoJson || {};
  const { features: rowsFeatures = [] } = quarterRowsGeoJson || {};
  const { features: plantsFeatures = [] } = plantsGeoJson || {};
  const [mapInstance, setMapInstance] = useState(null);
  const [searchText, setSearchText] = useState('');
  const { cancelRotateMap } = useRotateMap();
  const { mapColorType, setMapColorType } = useMapColorType({
    mapInstance,
    features: getVisibleFeatures({ tableRows, quartersFeatures, rowsFeatures, plantsFeatures }),
  });

  const highlightFeature = buildFeatureHighlighter(mapInstance);
  const unhighlightFeature = buildFeatureUnhighlighter(mapInstance);
  const highlightQuarter = (id) => highlightFeature(find(quartersFeatures, { id }));
  const highlightRow = (id) => highlightFeature(find(rowsFeatures, { id }));
  const fitQuarterBounds = (id) => {
    const feature = find(quartersFeatures, { id });
    const [minX, minY, maxX, maxY] = bbox(feature);

    cancelRotateMap();
    mapInstance.fitBounds([[minX, minY], [maxX, maxY]]);
  };
  const fitRowBounds = (id) => {
    const feature = find(rowsFeatures, { id });
    const [minX, minY, maxX, maxY] = bbox(feature);

    cancelRotateMap();
    mapInstance.fitBounds([[minX, minY], [maxX, maxY]]);
  };

  const columns = useMemo(() => {
    if (!mapInstance) return [];

    return buildColumns({
      tableRows, highlightQuarter, highlightRow, unhighlightFeature, fitQuarterBounds, fitRowBounds,
    });
  }, [mapInstance]);

  const handleTableChange = (p, f, s, { currentDataSource }) => {
    const allRowsIds = tableRows.map(({ id }) => id);
    const allQuartersIds = tableRows.map(({ quarterId }) => quarterId);
    const visibleRowsIds = currentDataSource.map(({ id }) => id);
    const hiddenRowsIds = difference(allRowsIds, visibleRowsIds);
    const visibleQuarterIds = currentDataSource.map(({ quarterId }) => quarterId);
    const hiddenQuartersIds = difference(allQuartersIds, visibleQuarterIds);
    const visibleQuartersFeatures = (
      quartersFeatures.filter(({ properties: { id } }) => visibleQuarterIds.includes(id))
    );
    const hiddenQuarters = (
      quartersFeatures.filter(({ properties: { id } }) => hiddenQuartersIds.includes(id))
    );
    const visibleQuarterRowsFeatures = (
      rowsFeatures.filter(({ properties: { id } }) => visibleRowsIds.includes(id))
    );
    const hiddenQuarterRows = (
      rowsFeatures.filter(({ properties: { id } }) => hiddenRowsIds.includes(id))
    );

    colorizeFeatures(visibleQuartersFeatures, mapInstance, mapColorType);
    colorizeFeatures(visibleQuarterRowsFeatures, mapInstance, mapColorType);
    colorizeFeatures(hiddenQuarters, mapInstance, mapColorType, { uncolorize: true });
    colorizeFeatures(hiddenQuarterRows, mapInstance, mapColorType, { uncolorize: true });
  };

  const buildOptions = () => {
    const quarters = (
      uniqBy(tableRows, 'quarter')
        .filter(({ quarter }) => quarter.toLowerCase().includes(searchText.toLowerCase()))
        .sort(stringSorterByAttribute('quarter'))
        .map(({ quarter, quarterId }) => ({
          label: (
            <div
              onMouseEnter={() => highlightQuarter(quarterId)}
              onClick={() => fitQuarterBounds(quarterId)}
            >
              {quarter}
            </div>
          ),
          value: quarter,
        }))
    );

    const rows = (
      uniqBy(tableRows, 'name')
        .filter(({ name }) => name.toLowerCase().includes(searchText.toLowerCase()))
        .sort(stringSorterByAttribute('name'))
        .map(({ id, name }) => ({
          label: (
            <div
              onMouseEnter={() => highlightRow(id)}
              onClick={() => fitRowBounds(id)}
            >
              {name}
            </div>
          ),
          value: name,
        }))
    );

    return [
      !isEmpty(quarters) && ({ label: i18n.t('quarters'), options: quarters }),
      !isEmpty(rows) && ({ label: i18n.t('quarter_rows'), options: rows }),
    ].filter(Boolean);
  };

  return (
    <Layout fullWidth>
      <AntdLayout style={{ height: '100%' }}>
        <Content>
          <Map
            geoJsonLayers={geoJsonLayers}
            bounds={quartersGeoJson && bbox(quartersGeoJson)}
            setMapInstance={setMapInstance}
            mapColorType={mapColorType}
            setMapColorType={setMapColorType}
            style={{ height: '100%' }}
          />
        </Content>
        <Sider className={siderStyles} width={600} trigger={null}>
          <Title level={2}>
            {i18n.t('my_orchard')}
            <sup>{totalArea}</sup>
          </Title>
          <AutoComplete options={buildOptions()} style={{ width: '100%', marginBottom: 20 }}>
            <Input
              placeholder={i18n.t('enter_quarter_or_row_name')}
              onChange={({ target }) => setSearchText(target.value)}
              suffix={<SearchOutlined style={{ color: '#999' }} />}
              allowClear
            />
          </AutoComplete>
          <Table
            className={tableStyles}
            columns={columns}
            dataSource={tableRows}
            onChange={handleTableChange}
            scroll={{ x: 700, y: 'calc(100vh - 295px)' }}
          />
        </Sider>
      </AntdLayout>
    </Layout>
  );
};

Monitoring.propTypes = {
  geoJsonLayers: shape({
    quartersGeoJson: shape({}),
    quarterRowsGeoJson: shape({}),
    plantsGeoJson: shape({}),
  }).isRequired,
  tableRows: arrayOf(shape({
    key: number,
    name: string,
    quarterName: string,
    cultivar: arrayOf(shape({})),
    crop: arrayOf(shape({})),
    plantsCount: number,
  })).isRequired,
  totalArea: string.isRequired,
};

export default Monitoring;
