import React from 'react';
import { Card, Badge, Col } from 'antd';
import { cropTypeNameToIconName } from 'icons/crop_types';

const CropType = ({ cropType, span, onCardClick }) => {
  const cropsCount = cropType.crops.length;
  const CropTypeIcon = cropTypeNameToIconName[cropType.name];

  return (
    <Col
      key={cropType.id}
      onClick={() => onCardClick(cropType.id)}
      span={span}
    >
      <Card
        title={cropType.humanName}
        extra={<Badge count={cropsCount} style={{ backgroundColor: '#999', marginLeft: 5 }} />}
        hoverable
      >
        <CropTypeIcon />
      </Card>
    </Col>
  );
};

export default CropType;
