import React from 'react';
import { arrayOf, number } from 'prop-types';
import { App, Table, Col, ColorPicker, Typography } from 'antd';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { cropPath, cultivarPath } from 'js-routes';
import { i18n } from 'helpers/i18n';
import { fetchData } from 'helpers/fetchData';
import { cultivarsTableStyles } from '../styles';
import { cropTypePropTypes, cropPropTypes } from '../propTypes';

const { Title } = Typography;

const submitForm = ({ cropId, cultivarId, color, message }) => {
  const item = cropId ? 'crop' : 'cultivar';
  const url = cropId ? cropPath(cropId) : cultivarPath(cultivarId);
  const formData = new FormData();
  formData.append(`${item}[color]`, color);

  fetchData({ url, method: 'PUT', body: formData })
    .then(({ errors }) => (!errors) && message.success(i18n.t(`${item}.message.updated`)));
};

const buildColumns = (message) => [
  { title: i18n.t('crop_catalog.crop_name'), dataIndex: 'humanName', width: '50%' },
  {
    title: i18n.t('color'),
    dataIndex: 'color',
    render: (color, { id }) => (
      <ColorPicker
        defaultValue={color}
        onChangeComplete={(newColor) => (
          submitForm({ cropId: id, color: newColor.toHexString(), message })
        )}
        showText
      />
    ),
    width: '50%',
  },
];

const expandedRowRenderBuilder = (message) => (crop) => {
  const { cultivars } = crop;
  const columns = [
    { title: i18n.t('crop_catalog.cultivar_name'), dataIndex: 'humanName', width: '50%' },
    {
      title: i18n.t('color'),
      dataIndex: 'color',
      render: (color, { id }) => (
        <ColorPicker
          defaultValue={color}
          onChangeComplete={(newColor) => (
            submitForm({ cultivarId: id, color: newColor.toHexString(), message })
          )}
          showText
        />
      ),
      width: '50%',
    },
  ];

  return (
    <Table
      className={cultivarsTableStyles}
      columns={columns}
      dataSource={cultivars}
      rowKey="id"
    />
  );
};

const expandIcon = ({ record, expanded, onExpand }) => {
  if (!record.cultivars.length) return null;

  const ExpandedIcon = expanded ? MinusCircleOutlined : PlusCircleOutlined;
  return <ExpandedIcon onClick={(e) => onExpand(record, e)} style={{ fontSize: '26px' }} />;
};

const CropTable = ({ cropType, crops, activeCropTypeId, span }) => {
  const { message } = App.useApp();
  if (activeCropTypeId !== cropType.id) return null;

  return (
    <Col key={cropType.id} span={span}>
      <Table
        columns={buildColumns(message)}
        dataSource={crops}
        title={() => <Title level={4} style={{ margin: 0 }}>{cropType.humanName}</Title>}
        pagination={false}
        rowKey="id"
        rowExpandable={(crop) => crop.cultivars.length > 0}
        expandable={{ expandedRowRender: expandedRowRenderBuilder(message), expandIcon }}
      />
    </Col>
  );
};

CropTable.propTypes = {
  cropType: cropTypePropTypes.isRequired,
  crops: arrayOf(cropPropTypes).isRequired,
  activeCropTypeId: number.isRequired,
  span: number.isRequired,
};

export default CropTable;
