import { useMediaQuery } from 'react-responsive';

const useBreakpoints = () => {
  const isXs = useMediaQuery({ query: '(max-width: 640px)' });
  const isSm = useMediaQuery({ query: '(min-width: 641px) and (max-width: 768px)' });
  const isMd = useMediaQuery({ query: '(min-width: 769px) and (max-width: 1024px)' });
  const isLg = useMediaQuery({ query: '(min-width: 1025px)' });

  return { isXs, isSm, isMd, isLg };
};

export default useBreakpoints;
