import React from 'react';
import { arrayOf, number, shape, string } from 'prop-types';
import { Row, Tag, Typography, Space, Button, Popconfirm, Descriptions } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import Layout from 'components/Layout';
import { quarterRowPath, editQuarterRowPath, monitoringPath } from 'js-routes';
import { i18n } from 'helpers/i18n';
import { fetchData } from 'helpers/fetchData';
import { snakeCase } from 'lodash';
import Map from 'components/Map';
import bbox from '@turf/bbox';

const { Title } = Typography;

const removeRowRequest = (id) => fetchData({
  url: quarterRowPath(id),
  method: 'DELETE',
}).then(() => { window.location.href = monitoringPath(); });

const buildDescriptionValue = (key, value) => {
  if (['cultivar', 'crop'].includes(key)) {
    return value.map(({ color, name }) => <Tag key={name} color={color}>{name}</Tag>);
  }

  return value;
};

const QuarterRow = ({ quarterRowAttributes, geoJsonLayers }) => {
  const { id, name } = quarterRowAttributes;

  const items = Object.entries(quarterRowAttributes).map(([key, value]) => ({
    label: i18n.t(`activerecord.attributes.quarter_row.${snakeCase(key)}`),
    children: buildDescriptionValue(key, value),
  }));

  return (
    <Layout>
      <Row justify="space-between" align="middle">
        <Title>{`${i18n.t('activerecord.models.quarter_row')} «${name}»`}</Title>

        <Space style={{ marginTop: 11 }}>
          <Button href={editQuarterRowPath(id)} icon={<EditOutlined />}>
            {i18n.t('edit')}
          </Button>
          <Popconfirm
            title={i18n.t('scouting_report.delete_popup_title')}
            description={i18n.t('scouting_report.delete_popup_description')}
            onConfirm={() => removeRowRequest(id)}
          >
            <Button type="primary" icon={<DeleteOutlined />} danger>
              {i18n.t('delete')}
            </Button>
          </Popconfirm>
        </Space>
      </Row>

      <Map
        geoJsonLayers={geoJsonLayers}
        bounds={bbox(geoJsonLayers.quarterRowsGeoJson)}
        isHoverEnabled={false}
        isClickEnabled={false}
      />

      <Descriptions
        items={items}
        column={1}
        contentStyle={{ display: 'block' }}
        style={{ margin: '20px 0' }}
        bordered
      />
    </Layout>
  );
};

QuarterRow.propTypes = {
  quarterRowAttributes: shape({
    id: number,
    name: string,
    quarter: string,
    crop: arrayOf(shape({})),
    cultivar: arrayOf(shape({})),
    plantsCount: number,
    description: string,
    createdAt: string,
    updatedAt: string,
  }).isRequired,
  geoJsonLayers: shape({
    quarterRowsGeoJson: shape({}),
  }).isRequired,
};

export default QuarterRow;
