import { userSessionPath } from 'js-routes';
import { decamelizeKeys } from 'humps';
import { fetchData } from 'helpers/fetchData';

const sendForm = (values) => fetchData({
  url: userSessionPath(),
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  body: { user: decamelizeKeys(values) },
}).then(() => { window.location.href = '/'; });

export { sendForm };
