let animationFrameId;

const useRotateMap = () => {
  const rotateMap = (map) => {
    const rotate = (timestamp) => {
      map.rotateTo((timestamp / 300) % 360, { duration: 0 });
      animationFrameId = requestAnimationFrame(rotate);
    };

    animationFrameId = requestAnimationFrame(rotate);
  };

  const cancelRotateMap = () => {
    if (!animationFrameId) return;

    cancelAnimationFrame(animationFrameId);
    animationFrameId = null;
  };

  return { rotateMap, cancelRotateMap };
};

export { useRotateMap };
