import { arrayOf, number, shape, string } from 'prop-types';

const manualWorkTypePropTypes = shape({
  id: number,
  name: string,
  humanName: string,
  color: string,
  cultivars: arrayOf(shape({
    id: number,
    name: string,
    humanName: string,
  })),
});

const manualWorkTypeGroupPropTypes = shape({
  id: number,
  name: string,
  humanName: string,
  crops: arrayOf(manualWorkTypePropTypes),
});

export { manualWorkTypeGroupPropTypes, manualWorkTypePropTypes };
