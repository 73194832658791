import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { ConfigProvider } from 'antd';
import { colorPrimary, whiteColor, borderRadius } from 'constants';
import { antdLocale } from 'helpers/i18n';

const AppConfigProvider = ({ children }) => (
  <ConfigProvider
    locale={antdLocale}
    theme={{
      token: { colorPrimary, borderRadius, colorLink: colorPrimary },
      components: {
        Segmented: { itemSelectedBg: colorPrimary, itemSelectedColor: whiteColor },
      },
    }}
  >
    {children}
  </ConfigProvider>
);

AppConfigProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default memo(AppConfigProvider);
