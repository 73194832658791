import React, { memo } from 'react';
import { App, Layout, Menu, Row } from 'antd';
import AppConfigProvider from './AppConfigProvider';
import { menuContainerStyles, leftMenuStyles, rightMenuStyles } from './styles';
import { leftMenuItems, rightMenuItems, omitCustomData } from './menuItems';
import FilterButton from './FilterButton';

const selectedLeftMenuItem = (
  leftMenuItems.find((item) => window.location.pathname.includes(item.urlPath))
);

const clickMenuItem = (menuItems, key) => {
  menuItems.forEach((item) => {
    if (item.key === key) { item.onClick?.(); return; }
    if (item.children) clickMenuItem(item.children, key);
  });
};

const Header = () => (
  <AppConfigProvider>
    <App>
      <Layout>
        <Row className={menuContainerStyles}>
          <Menu
            className={leftMenuStyles}
            items={omitCustomData(leftMenuItems)}
            defaultSelectedKeys={[selectedLeftMenuItem?.key]}
            onClick={({ key }) => clickMenuItem(leftMenuItems, key)}
            mode="horizontal"
          />
          <Row>
            <FilterButton />
            <Menu
              className={rightMenuStyles}
              items={omitCustomData(rightMenuItems)}
              onClick={({ key }) => clickMenuItem(rightMenuItems, key)}
              mode="horizontal"
              forceSubMenuRender
            />
          </Row>
        </Row>
      </Layout>
    </App>
  </AppConfigProvider>
);

export default memo(Header);
