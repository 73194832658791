import React, { useState, useEffect, useRef } from 'react';
import { arrayOf, shape } from 'prop-types';
import { newScoutingReportPath } from 'js-routes';
import { Row, Button, Table, Space, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Layout from 'components/Layout';
import Map from 'components/Map';
import useBreakpoints from 'hooks/useBreakpoints';
import { i18n } from 'helpers/i18n';
import maplibregl from 'maplibre-gl';
import { point, featureCollection } from '@turf/helpers';
import bbox from '@turf/bbox';
import { columns } from './columns';
import { tableStyles } from './styles';

const { Title } = Typography;

const ScoutingReports = ({ scoutingReports, geoJsonLayers }) => {
  const mapRef = useRef(null);
  const [bounds, setBounds] = useState(null);
  const { isXs } = useBreakpoints();
  const { quartersGeoJson } = geoJsonLayers;

  useEffect(() => {
    const coordinates = scoutingReports.map(({ location }) => location);
    const points = featureCollection(coordinates.map(point));

    if (points.features.length) setBounds(bbox(points));

    coordinates.forEach((coords) => {
      new maplibregl.Marker().setLngLat(coords).addTo(mapRef.current);
    });
  }, []);

  return (
    <Layout>
      <Row justify="space-between" align="middle">
        <Title level={isXs ? 2 : 1}>{i18n.t('scouting_reports')}</Title>

        <Button type="primary" href={newScoutingReportPath()} style={{ marginTop: 11 }}>
          <PlusOutlined />
          {i18n.t('create')}
        </Button>
      </Row>

      <Space direction="vertical" style={{ width: '100%' }}>
        <Map
          mapRef={mapRef}
          bounds={bounds || (quartersGeoJson && bbox(quartersGeoJson))}
          geoJsonLayers={geoJsonLayers}
          isHoverEnabled={false}
          isClickEnabled={false}
        />

        <Table
          className={tableStyles}
          columns={columns}
          dataSource={scoutingReports}
          size={isXs ? 'small' : 'middle'}
        />
      </Space>
    </Layout>
  );
};

ScoutingReports.propTypes = {
  scoutingReports: arrayOf(shape({})).isRequired,
  geoJsonLayers: shape({
    quartersGeoJson: shape({}),
    quarterRowsGeoJson: shape({}),
    plantsGeoJson: shape({}),
  }),
};

ScoutingReports.defaultProps = {
  geoJsonLayers: {},
};

export default ScoutingReports;
