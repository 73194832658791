import { css } from '@emotion/css';

const cultivarsTableStyles = css`
  .ant-pagination {
    margin-top: 32px !important;
    margin-bottom: 0 !important;
  }
`;

export { cultivarsTableStyles };
