import React, { useState, useRef, Fragment } from 'react';
import { arrayOf } from 'prop-types';
import { Typography, Space, Row } from 'antd';
import { i18n } from 'helpers/i18n';
import Layout from 'components/Layout';
import { cropTypePropTypes } from './propTypes';
import CropTable from './components/CropTable';
import CropType from './components/CropType';

const CROP_TYPE_SPAN = 6;
const TABLE_SPAN = 24 - CROP_TYPE_SPAN;

const CropsCatalog = ({ cropTypes }) => {
  const [activeCropTypeId, setActiveCropTypeId] = useState(null);
  const scrollRef = useRef(null);

  const onCardClick = (cropTypeId) => {
    if (scrollRef.current) scrollRef.current.scrollIntoView({ behavior: 'smooth' });

    setActiveCropTypeId(activeCropTypeId === cropTypeId ? null : cropTypeId);
  };

  const getOrderedCropTypes = () => {
    if (!activeCropTypeId) return cropTypes;
    const activeCropType = cropTypes.find((ct) => ct.id === activeCropTypeId);
    const otherCropTypes = cropTypes.filter((ct) => ct.id !== activeCropTypeId);

    return [activeCropType, ...otherCropTypes];
  };

  return (
    <Layout>
      <Typography.Title>{`${i18n.t('crop_catalog.title')}`}</Typography.Title>

      <Space direction="vertical" style={{ width: '100%', marginBottom: 20 }}>
        <Row gutter={[16, 16]}>
          {getOrderedCropTypes().map((cropType) => (
            <Fragment key={cropType.id}>
              <CropType cropType={cropType} onCardClick={onCardClick} span={CROP_TYPE_SPAN} />

              <CropTable
                cropType={cropType}
                crops={cropType.crops}
                activeCropTypeId={activeCropTypeId}
                span={TABLE_SPAN}
              />
            </Fragment>
          ))}
        </Row>
      </Space>
    </Layout>
  );
};

CropsCatalog.propTypes = {
  cropTypes: arrayOf(cropTypePropTypes).isRequired,
};

export default CropsCatalog;
