import uk from 'antd/locale/uk_UA';
import ru from 'antd/locale/ru_RU';
import en from 'antd/locale/en_US';
import { I18n } from 'i18n-js';

const currentLocale = document.documentElement.lang;
const antdLocale = { uk, ru, en }[currentLocale];

const i18n = new I18n(window.translations);
i18n.locale = currentLocale;

const dateFormatter = new Intl.DateTimeFormat(
  i18n.locale,
  { month: 'long', day: 'numeric', year: 'numeric' },
);

export { i18n, antdLocale, dateFormatter };
