import React from 'react';
import { number, shape, string, oneOfType, arrayOf } from 'prop-types';
import { Card, Badge, Tag, Descriptions } from 'antd';
import { i18n } from 'helpers/i18n';
import { snakeCase, pick, isNil } from 'lodash';
import { isJSON } from 'helpers';
import { colorPrimary } from 'constants';
import { popupStyles } from '../../styles';

const buildDescriptionValue = (key, stringifiedValue) => {
  const value = isJSON(stringifiedValue) ? JSON.parse(stringifiedValue) : stringifiedValue;
  if (isNil(value)) return '—';

  if (['cultivar', 'crop'].includes(key)) {
    return value.map(({ color, name }) => <Tag key={name} color={color}>{name}</Tag>);
  }

  return value;
};

const QuarterPopup = ({ properties }) => {
  const descriptionItems = (
    Object
      .entries(pick(properties, ['cultivar', 'crop', 'area', 'rowsCount', 'description']))
      .map(([key, value]) => ({
        label: i18n.t(`activerecord.attributes.quarter.${snakeCase(key)}`),
        children: buildDescriptionValue(key, value),
      }))
  );

  return (
    <Badge.Ribbon text={i18n.t('activerecord.models.quarter')} color={colorPrimary}>
      <Card title={properties.name} size="small" style={{ minWidth: 300 }}>
        <Descriptions className={popupStyles} items={descriptionItems} column={1} />
      </Card>
    </Badge.Ribbon>
  );
};

QuarterPopup.propTypes = {
  properties: shape({
    name: string,
    area: string,
    rowsCount: number,
    cultivar: oneOfType([string, arrayOf(shape({ name: string, color: string }))]),
    crop: oneOfType([string, arrayOf(shape({ name: string, color: string }))]),
    description: string,
  }).isRequired,
};

export default QuarterPopup;
