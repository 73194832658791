import React from 'react';
import { arrayOf, number, shape, string } from 'prop-types';
import Layout from 'components/Layout';
import { Row, Tag, Typography, Space, Button, Popconfirm, Descriptions } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { i18n } from 'helpers/i18n';
import { fetchData } from 'helpers/fetchData';
import { snakeCase } from 'lodash';
import { plantPath, editPlantPath, monitoringPath } from 'js-routes';
import Map from 'components/Map';
import bbox from '@turf/bbox';

const removePlantRequest = (id) => fetchData({
  url: plantPath(id),
  method: 'DELETE',
}).then(() => { window.location.href = monitoringPath(); });

const buildDescriptionValue = (key, value) => {
  if (['cultivar', 'crop'].includes(key)) {
    return value.map(({ color, name }) => <Tag key={name} color={color}>{name}</Tag>);
  }

  return value;
};

const Plant = ({ plantAttributes, geoJsonLayers }) => {
  const { id } = plantAttributes;

  const items = Object.entries(plantAttributes).map(([key, value]) => (
    {
      label: i18n.t(`activerecord.attributes.plant.${snakeCase(key)}`),
      children: buildDescriptionValue(key, value),
    }
  ));

  return (
    <Layout>
      <Row justify="space-between" align="middle">
        <Typography.Title>{`${i18n.t('activerecord.models.plant')} #${id}`}</Typography.Title>

        <Space>
          <Button href={editPlantPath(id)} icon={<EditOutlined />}>
            {i18n.t('edit')}
          </Button>

          <Popconfirm
            title={i18n.t('plant.delete_popup_title')}
            description={i18n.t('plant.delete_popup_description')}
            onConfirm={() => removePlantRequest(id)}
          >
            <Button type="primary" icon={<DeleteOutlined />} danger>
              {i18n.t('delete')}
            </Button>
          </Popconfirm>
        </Space>
      </Row>

      <Map
        geoJsonLayers={geoJsonLayers}
        bounds={bbox(geoJsonLayers.plantsGeoJson)}
        isHoverEnabled={false}
        isClickEnabled={false}
      />

      <Descriptions
        items={items}
        column={1}
        contentStyle={{ display: 'block' }}
        style={{ margin: '20px 0' }}
        bordered
      />
    </Layout>
  );
};

Plant.propTypes = {
  plantAttributes: shape({
    id: number,
    quarter: string,
    quarterRow: string,
    crop: arrayOf(shape({})),
    cultivar: arrayOf(shape({})),
    rootstock: string,
    is_pollinator: string,
    planting_date: string,
    createdAt: string,
    updatedAt: string,
  }).isRequired,
  geoJsonLayers: shape({
    plantsGeoJson: shape({}),
  }).isRequired,
};

export default Plant;
