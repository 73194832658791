import React, { useState, useRef, useEffect } from 'react';
import { arrayOf, number, shape } from 'prop-types';
import { Typography, Space } from 'antd';
import { point as turfPoint, multiPolygon as turfMultiPolygon } from '@turf/helpers';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import bbox from '@turf/bbox';
import maplibregl from 'maplibre-gl';
import Map from 'components/Map';
import { i18n } from 'helpers/i18n';
import { pascalize } from 'humps';
import { quarterNameLocationStyles, markerPositionStyles } from './styles';

const { Text } = Typography;

const updateLocation = ({
  form,
  marker,
  geoJsonLayers: { quartersGeoJson, quarterRowsGeoJson },
  setQuarterNameLocation,
  setQuarterRowNameLocation,
  setMarkerCoordinates,
}) => {
  const markerLocation = Object.values(marker.getLngLat());
  const hoveredQuarter = quartersGeoJson?.features.find((quarter) => (
    booleanPointInPolygon(markerLocation, turfMultiPolygon(quarter.geometry.coordinates))
  ));
  const hoveredQuarterRow = quarterRowsGeoJson?.features.find((quarterRow) => (
    booleanPointInPolygon(markerLocation, turfMultiPolygon(quarterRow.geometry.coordinates))
  ));
  const hoveredFeature = hoveredQuarterRow || hoveredQuarter;

  setQuarterNameLocation(hoveredQuarter?.properties?.name || '?');
  setQuarterRowNameLocation(hoveredQuarterRow?.properties?.name || null);
  setMarkerCoordinates(markerLocation);

  form.setFieldsValue({
    location: markerLocation,
    scoutableType: hoveredFeature ? pascalize(hoveredFeature.properties.featureType) : null,
    scoutableId: hoveredFeature?.properties?.id || null,
  });
};

const ScoutingReportFormMap = ({ form, location, geoJsonLayers }) => {
  const mapRef = useRef(null);
  const [quarterNameLocation, setQuarterNameLocation] = useState('');
  const [quarterRowNameLocation, setQuarterRowNameLocation] = useState('');
  const [[markerLng, markerLat], setMarkerCoordinates] = useState([null, null]);
  const [bounds, setBounds] = useState(null);
  const [isCoordsShort, setIsCoordsShort] = useState(true);
  const { quartersGeoJson } = geoJsonLayers;

  useEffect(() => {
    const { lng: centerLng, lat: centerLat } = mapRef.current.getCenter();
    const markerLocation = location || [centerLng, centerLat];
    const point = turfPoint(markerLocation);
    const marker = (
      new maplibregl.Marker({ draggable: true }).setLngLat(markerLocation).addTo(mapRef.current)
    );

    const params = {
      form,
      marker,
      geoJsonLayers,
      setMarkerCoordinates,
      setQuarterNameLocation,
      setQuarterRowNameLocation,
    };

    marker.on('dragend', () => updateLocation(params));
    updateLocation(params);

    setBounds(bbox(point));
  }, []);

  return (
    <>
      <Map
        mapRef={mapRef}
        bounds={bounds || bbox(quartersGeoJson)}
        geoJsonLayers={geoJsonLayers}
        isHoverEnabled={false}
        isClickEnabled={false}
      />

      <Space className={quarterNameLocationStyles} size={5}>
        <Text type="secondary">{`${i18n.t('activerecord.models.quarter')}:`}</Text>
        <Text>{`${quarterNameLocation}${quarterRowNameLocation ? ';' : ''}`}</Text>

        {quarterRowNameLocation && (
          <>
            <Text type="secondary">{`${i18n.t('activerecord.models.quarter_row')}:`}</Text>
            <Text>{quarterRowNameLocation}</Text>
          </>
        )}
      </Space>

      <Space className={markerPositionStyles} size={5} onClick={() => setIsCoordsShort((b) => !b)}>
        <Text type="secondary">lng:</Text>
        <Text>{isCoordsShort ? markerLng?.toFixed(4) : markerLng}</Text>
        <Text type="secondary">lat:</Text>
        <Text>{isCoordsShort ? markerLat?.toFixed(4) : markerLat}</Text>
      </Space>
    </>
  );
};

ScoutingReportFormMap.propTypes = {
  geoJsonLayers: shape({}),
  location: arrayOf(number),
  form: shape({}).isRequired,
};

ScoutingReportFormMap.defaultProps = {
  geoJsonLayers: {},
  location: null,
};

export default ScoutingReportFormMap;
