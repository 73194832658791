import { css } from '@emotion/css';

const outerContainerStyles = ({ height }) => css`
  position: relative;
  width: 100%;
  ${`height: ${height};`}

  .maplibregl-canvas {
    border-radius: 10px;
  }

  .maplibregl-popup-content {
    border-radius: 10px;
    padding: 0;
  }

  .maplibregl-popup-tip {
    display: none;
  }

  .feature-popup {
    position: absolute;
    transform: none !important;
    max-width: unset !important;
    left: auto;
    top: 10px;
    right: 60px;
  }
`;

const innerContainerStyles = css`
  height: 100%;
  width: 100%;
`;

const popupStyles = css`
  .ant-descriptions-view {
    table {
      width: auto;
    }

    .ant-descriptions-item {
      padding-bottom: 0;
    }
  }
`;

export { outerContainerStyles, innerContainerStyles, popupStyles };
