import React, { useEffect, useRef } from 'react';
import { arrayOf, bool, number, shape, oneOfType, func } from 'prop-types';
import { Row } from 'antd';
import maplibregl from 'maplibre-gl';
import { countryBounds } from 'helpers/userSession';
import MapStyleSwitcher, { defaultMapStyle } from './components/MapStyleSwitcher';
import MapColoringSwitcher from './components/MapColoringSwitcher';
import { useRotateMap } from './hooks/useRotateMap';
import { outerContainerStyles, innerContainerStyles } from './styles';
import { addHoverEffect } from './helpers/featureHighlight';
import {
  buildQuartersFillLayer,
  buildQuartersBordersLayer,
  buildQuartersLabelsLayer,
  buildQuarterRowsFillLayer,
  buildQuarterRowsBordersFillLayer,
  buildQuarterRowsLabelsLayer,
  buildPlantsFillLayer,
  buildPlantsBordersLayer,
} from './layers';
import {
  addSources,
  addLayers,
  addClickHandler,
  buildCircles,
  buildSources,
} from './helpers';

const mapContainerId = 'mapContainerId';

const Map = ({
  geoJsonLayers,
  bounds,
  isHoverEnabled,
  isClickEnabled,
  setMapInstance,
  mapColorType,
  setMapColorType,
  mapRef: mapRefFromProps,
  style: { height = '400px' },
}) => {
  const innerMapRef = useRef(null);
  const mapRef = mapRefFromProps || innerMapRef;
  const { quartersGeoJson, quarterRowsGeoJson, plantsGeoJson } = geoJsonLayers;
  const { rotateMap, cancelRotateMap } = useRotateMap();

  useEffect(() => {
    const map = new maplibregl.Map({
      container: mapContainerId,
      bounds: bounds || countryBounds,
      style: defaultMapStyle.url,
      pitch: 50,
      attributionControl: false,
    });

    map.on('style.load', () => {
      addSources(map, buildSources({
        quartersGeoJson,
        quarterRowsGeoJson,
        plantsGeoJson: buildCircles(plantsGeoJson),
      }));

      addLayers(map, [
        buildQuartersFillLayer(),
        buildQuartersBordersLayer(),
        buildQuartersLabelsLayer(),
        buildQuarterRowsFillLayer(),
        buildQuarterRowsBordersFillLayer(),
        buildQuarterRowsLabelsLayer(),
        buildPlantsFillLayer(),
        buildPlantsBordersLayer(),
      ]);
    });

    map.on('load', () => {
      addHoverEffect({ map, isHoverEnabled });
      addClickHandler({ map, isClickEnabled });
      rotateMap(map);

      setMapInstance(map);
    });

    map.on('mousedown', cancelRotateMap);

    map.addControl(new maplibregl.NavigationControl());
    map.addControl(new maplibregl.ScaleControl());

    mapRef.current = map;

    return () => mapRef.current.remove();
  }, []);

  return (
    <Row className={outerContainerStyles({ height })}>
      <div id={mapContainerId} className={innerContainerStyles} />
      <MapStyleSwitcher mapRef={mapRef} />
      <MapColoringSwitcher mapColorType={mapColorType} setMapColorType={setMapColorType} />
    </Row>
  );
};

Map.propTypes = {
  mapRef: shape({ current: shape({}) }),
  geoJsonLayers: shape({
    quartersGeoJson: shape({}),
    quarterRowsGeoJson: shape({}),
    plantsGeoJson: shape({}),
  }),
  bounds: arrayOf(oneOfType([arrayOf(number), number])),
  isHoverEnabled: bool,
  isClickEnabled: bool,
  setMapInstance: func,
  style: shape({}),
};

Map.defaultProps = {
  mapRef: null,
  geoJsonLayers: {},
  bounds: null,
  isHoverEnabled: true,
  isClickEnabled: true,
  setMapInstance: () => {},
  style: {},
};

export default Map;
