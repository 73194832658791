import React, { useState, useEffect } from 'react';
import { ConfigProvider, Button, Checkbox, Form, Input, Row, Typography } from 'antd';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { antdLocale } from 'helpers/i18n';
import { formStyles, iconStyles } from './styles';
import { sendForm } from './helpers';

const Login = () => {
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
  const [emailInput, setEmailInput] = useState('');
  const [passwordInput, setPasswordInput] = useState('');

  useEffect(() => {
    setIsSubmitButtonDisabled(!emailInput.trim() || !passwordInput.trim());
  }, [emailInput, passwordInput]);

  return (
    <ConfigProvider
      theme={{ token: { colorPrimary: '#00b96b', colorLink: '#00b96b', borderRadius: 10 } }}
      locale={antdLocale}
    >
      <Form
        className={formStyles}
        initialValues={{ rememberMe: true }}
        onFinish={sendForm}
      >
        <Form.Item name="email" rules={[{ required: true, message: '' }]}>
          <Input
            onChange={(event) => setEmailInput(event.target.value)}
            type="email"
            placeholder="Email"
            prefix={<MailOutlined className={iconStyles} />}
          />
        </Form.Item>
        <Form.Item name="password" rules={[{ required: true, message: '' }]}>
          <Input.Password
            onChange={(event) => setPasswordInput(event.target.value)}
            placeholder="Password"
            prefix={<LockOutlined className={iconStyles} />}
          />
        </Form.Item>
        <Row justify="space-between" className="ant-form-item">
          <Form.Item name="rememberMe" valuePropName="checked" noStyle>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
          <Typography.Link href="">Forgot password</Typography.Link>
        </Row>
        <Form.Item>
          <Button
            disabled={isSubmitButtonDisabled}
            type="primary"
            htmlType="submit"
            block
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    </ConfigProvider>
  );
};

export default Login;
