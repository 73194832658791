import React, { useState } from 'react';
import { Button, Space, Steps, Typography } from 'antd';
import Layout from 'components/Layout';
import { fetchData } from 'helpers/fetchData';
import { decamelizeKeys } from 'humps';
import { i18n } from 'helpers/i18n';
import useBreakpoints from 'hooks/useBreakpoints';
import { draggerStyles } from './styles';
import { shapesTypes } from './shapesTypes';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';

const createShapes = ({ shapesType, geoJson }) => () => (
  fetchData({
    url: shapesTypes[shapesType].url,
    method: 'POST',
    contentType: 'json',
    responseType: 'text',
    body: decamelizeKeys({ geoJson }),
  }).then(() => { window.location.href = '/'; })
);

const { Title } = Typography;

const ShapeFilesUploader = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [shapesType, setShapesType] = useState(Object.keys(shapesTypes)[0]);
  const [geoJson, setGeoJson] = useState(null);
  const { isXs } = useBreakpoints();
  const stepsItems = [
    {
      key: 1,
      title: i18n.t('shapefiles_uploader.upload_shapefiles'),
      component: <FirstStep setGeojson={setGeoJson} setShapesType={setShapesType} />,
    },
    {
      key: 2,
      title: i18n.t('shapefiles_uploader.check_uploaded_shapes'),
      component: <SecondStep shapesType={shapesType} geoJson={geoJson} />,
    },
  ];

  return (
    <Layout>
      <Title level={isXs ? 3 : 1}>{i18n.t('shapefiles_uploader.shapefiles_uploader')}</Title>

      <Space size={25} className={draggerStyles}>
        <Steps current={currentStep} items={stepsItems} />

        {stepsItems[currentStep].component}

        <Space>
          <Button
            disabled={!geoJson}
            onClick={() => setCurrentStep((step) => (step ? (step - 1) : (step + 1)))}
          >
            {currentStep ? i18n.t('previous') : i18n.t('next')}
          </Button>

          <Button
            type="primary"
            onClick={createShapes({ shapesType, geoJson })}
            style={{ visibility: (currentStep === 1) ? 'visible' : 'hidden' }}
          >
            {i18n.t('create')}
          </Button>
        </Space>
      </Space>
    </Layout>
  );
};

export default ShapeFilesUploader;
