import React from 'react';
import { i18n, dateFormatter } from 'helpers/i18n';
import { Image, Flex } from 'antd';
import { scoutingReportPath } from 'js-routes';

const columns = [
  {
    title: i18n.t('activerecord.attributes.scouting_report.name'),
    dataIndex: 'name',
    render: (_, record) => <a href={scoutingReportPath(record.id)}>{record.name}</a>,
  },
  {
    title: i18n.t('photos'),
    dataIndex: 'photosUrls',
    render: (_, { photosUrls }) => (
      <Flex gap="small" wrap="wrap">
        <Image.PreviewGroup>
          {photosUrls.map((photoUrl) => (
            <Image
              key={photoUrl}
              src={photoUrl}
              width={100}
              height={100}
              style={{ objectFit: 'cover' }}
            />
          ))}
        </Image.PreviewGroup>
      </Flex>
    ),
  },
  {
    title: i18n.t('activerecord.attributes.scouting_report.description'),
    dataIndex: 'description',
  },
  {
    title: i18n.t('activerecord.attributes.scouting_report.user'),
    dataIndex: 'authorName',
  },
  {
    title: i18n.t('scouting_report.assigned_users'),
    dataIndex: 'assignedUsers',
    render: (assignedUsers) => assignedUsers.join(', '),
  },
  {
    title: i18n.t('activerecord.attributes.scouting_report.created_at'),
    dataIndex: 'createdAt',
    render: (_, { createdAt }) => dateFormatter.format(new Date(createdAt)),
  },
];

export { columns };
