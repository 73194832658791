import React, { useState, useEffect } from 'react';
import { Table, DatePicker, Select, Button, Spin, message } from 'antd';
import Layout from 'components/Layout';
import { fetchData } from 'helpers/fetchData';
import { harvestSummaryManualWorksPath } from 'js-routes';
import { i18n } from 'helpers/i18n';
import {
  filterContainerStyles,
  mainStyles,
  scrollableContainerStyles,
  headerStyles,
  tableStyles,
  loadingContainer,
} from './styles';

const { RangePicker } = DatePicker;
const { Option } = Select;

const dateColumns = [
  {
    title: i18n.t('harvest.index.columns.date'),
    dataIndex: 'dayDate',
    key: 'dayDate',
  },
  {
    title: i18n.t('harvest.index.columns.bins'),
    dataIndex: 'dayTotalBins',
    key: 'dayTotalBins',
  },
];

const pickerColumns = [
  {
    title: i18n.t('harvest.index.columns.picker_name'),
    dataIndex: 'pickerName',
    key: 'pickerName',
  },
  {
    title: i18n.t('harvest.index.columns.bins'),
    dataIndex: 'pickerBins',
    key: 'pickerBins',
  },
];

const orchardColumns = [
  {
    title: i18n.t('harvest.index.columns.orchard'),
    dataIndex: 'orchardName',
    key: 'orchardName',
  },
  {
    title: i18n.t('harvest.index.columns.bins'),
    dataIndex: 'orchardBins',
    key: 'orchardBins',
  },
];

const quarterColumns = [
  {
    title: i18n.t('harvest.index.columns.quarter'),
    dataIndex: 'quarterTallies',
    key: 'quarterTallies',
  },
  {
    title: i18n.t('harvest.index.columns.bins'),
    dataIndex: 'quarterBins',
    key: 'quarterBins',
  },
];

const varietyColumns = [
  {
    title: i18n.t('harvest.index.columns.variety'),
    dataIndex: 'varietyTallies',
    key: 'varietyTallies',
  },
  {
    title: i18n.t('harvest.index.columns.bins'),
    dataIndex: 'varietyBins',
    key: 'varietyBins',
  },
];

const pickColumns = [
  {
    title: i18n.t('harvest.index.columns.variety'),
    dataIndex: 'pickVariety',
    key: 'pickVariety',
  },
  {
    title: i18n.t('harvest.index.columns.bins'),
    dataIndex: 'pickTallies',
    key: 'pickTallies',
  },
];

const Harvest = ({ orchards }) => {
  const [filteredData, setFilteredData] = useState({
    dateData: [],
    pickerData: [],
    orchardData: [],
    quarterData: [],
    varietyData: [],
    pickData: [],
  });
  const [quarterOptions, setQuarterOptions] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [selectedOrchard, setSelectedOrchard] = useState(null);
  const [selectedQuarter, setSelectedQuarter] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const tables = [
    {
      title: i18n.t('harvest.index.headers.day_tallies'),
      columns: dateColumns,
      data: filteredData.dateData,
    },
    {
      title: i18n.t('harvest.index.headers.picker_tallies'),
      columns: pickerColumns,
      data: filteredData.pickerData,
    },
    {
      title: i18n.t('harvest.index.headers.orchard_tallies'),
      columns: orchardColumns,
      data: filteredData.orchardData,
    },
    {
      title: i18n.t('harvest.index.headers.quarter_tallies'),
      columns: quarterColumns,
      data: filteredData.quarterData,
    },
    {
      title: i18n.t('harvest.index.headers.variety_tallies'),
      columns: varietyColumns,
      data: filteredData.varietyData,
    },
    {
      title: i18n.t('harvest.index.headers.pick_tallies'),
      columns: pickColumns,
      data: filteredData.pickData,
    },
  ];

  const handleDateChange = (dates) => setSelectedDateRange(dates);
  const handleOrchardChange = (value) => {
    setSelectedOrchard(value);
    setSelectedQuarter(null);
  };
  const handleQuarterChange = (value) => setSelectedQuarter(value);

  const resetFilters = () => {
    setSelectedDateRange(null);
    setSelectedOrchard(null);
    setSelectedQuarter(null);
  };

  const controller = new AbortController();

  const fetchFilteredData = async () => {
    setIsLoading(true);
    try {
      const params = {
        start_date: selectedDateRange?.[0]?.format('DD-MM-YYYY'),
        end_date: selectedDateRange?.[1]?.format('DD-MM-YYYY'),
        orchard_ids: [selectedOrchard],
        quarter_ids: [selectedQuarter],
      };

      const url = harvestSummaryManualWorksPath({ ...params });

      const response = await fetchData({
        url,
        method: 'GET',
        headers: {},
        contentType: 'json',
        responseType: 'json',
      });

      setQuarterOptions(response.filterOptions.quarters);
      setFilteredData(response);
    } catch (error) {
      message.error('Failed to fetch data. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchFilteredData();
    return () => controller.abort();
  }, [selectedDateRange, selectedOrchard, selectedQuarter]);

  return (
    <Layout className={mainStyles} fullWidth>
      <h1>{i18n.t('harvest.index.title')}</h1>
      <div className={filterContainerStyles}>
        <RangePicker
          onChange={handleDateChange}
          value={selectedDateRange}
          format="DD-MM-YYYY"
        />
        <Select
          placeholder={`${i18n.t('all')} ${i18n.t('harvest.index.orchards')}`}
          onChange={handleOrchardChange}
          value={selectedOrchard}
          style={{ width: 200 }}
        >
          {orchards.map((orchard) => (
            <Option key={orchard.id} value={orchard.id}>
              {orchard.name}
            </Option>
          ))}
        </Select>
        <Select
          placeholder={`${i18n.t('all')} ${i18n.t('quarters')}`}
          onChange={handleQuarterChange}
          value={selectedQuarter}
          style={{ width: 200 }}
          loading={isLoading}
        >
          {quarterOptions.map((quarter) => (
            <Option key={quarter.id} value={quarter.id}>
              {quarter.name}
            </Option>
          ))}
        </Select>
        <Button onClick={resetFilters}>{i18n.t('harvest.index.reset_filters')}</Button>
      </div>

      {isLoading ? (
        <div className={loadingContainer}>
          <Spin size="large" />
        </div>
      ) : (

        <div className={scrollableContainerStyles}>
          {tables.map((table) => (
            <div key={table.title} style={{ textAlign: 'center' }}>
              <div className={headerStyles}>{table.title}</div>
              <Table
                className={tableStyles}
                columns={table.columns}
                dataSource={table.data}
                pagination={false}
                size="small"
                components={{
                  header: {
                    cell: (props) => (
                      <th
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...props}
                        style={{
                          backgroundColor: 'transparent',
                          borderBottom: '1px solid #d9d9d9',
                          fontWeight: 'bold',
                        }}
                      />
                    ),
                  },
                }}
              />
            </div>
          ))}
        </div>
      )}
    </Layout>
  );
};

export default Harvest;
