import React, { useState, useRef, Fragment } from 'react';
import { arrayOf } from 'prop-types';
import { Typography, Space, Row } from 'antd';
import { i18n } from 'helpers/i18n';
import Layout from 'components/Layout';
import { manualWorkTypeGroupPropTypes } from './propTypes';
import WorkTypeTable from './components/WorkTypeTable';
import WorkTypeGroup from './components/WorkTypeGroup';

const CROP_TYPE_SPAN = 6;
const TABLE_SPAN = 24 - CROP_TYPE_SPAN;

const ManualWorkTypesCatalog = ({ manualWorkTypeGroups }) => {
  const [activeWorkTypeGroupId, setActiveWorkTypeGroupId] = useState(null);
  const scrollRef = useRef(null);

  const onCardClick = (cropTypeId) => {
    if (scrollRef.current) scrollRef.current.scrollIntoView({ behavior: 'smooth' });

    setActiveWorkTypeGroupId(activeWorkTypeGroupId === cropTypeId ? null : cropTypeId);
  };

  const getOrderedWorkTypeGroups = () => {
    if (!activeWorkTypeGroupId) return manualWorkTypeGroups;
    const active = manualWorkTypeGroups.find((ct) => ct.id === activeWorkTypeGroupId);
    const other = manualWorkTypeGroups.filter((ct) => ct.id !== activeWorkTypeGroupId);

    return [active, ...other];
  };

  return (
    <Layout>
      <Typography.Title>{`${i18n.t('manual_work_type_catalog.title')}`}</Typography.Title>

      <Space direction="vertical" style={{ width: '100%', marginBottom: 20 }}>
        <Row gutter={[16, 16]}>
          {getOrderedWorkTypeGroups().map((workTypeGroup) => (
            <Fragment key={workTypeGroup.id}>
              <WorkTypeGroup
                workTypeGroup={workTypeGroup}
                onCardClick={onCardClick}
                span={CROP_TYPE_SPAN}
              />

              <WorkTypeTable
                workTypeGroup={workTypeGroup}
                workTypes={workTypeGroup.workTypes}
                activeWorkTypeGroupId={activeWorkTypeGroupId}
                span={TABLE_SPAN}
              />
            </Fragment>
          ))}
        </Row>
      </Space>
    </Layout>
  );
};

ManualWorkTypesCatalog.propTypes = {
  manualWorkTypeGroups: arrayOf(manualWorkTypeGroupPropTypes).isRequired,
};

export default ManualWorkTypesCatalog;
