import { useState, useEffect } from 'react';
import { defaultMapColorType } from '../components/MapColoringSwitcher';
import { colorizeFeatures } from '../helpers';

const useMapColorType = ({ mapInstance, features }) => {
  const [mapColorType, setMapColorType] = useState(defaultMapColorType);

  useEffect(() => {
    if (!mapInstance) return;

    colorizeFeatures(features, mapInstance, mapColorType);
  }, [mapColorType, mapInstance]);

  return { mapColorType, setMapColorType };
};

export { useMapColorType };
