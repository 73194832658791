import React, { useRef } from 'react';
import Layout from 'components/Layout';
import { Form, Input, Button, Typography } from 'antd';
import { i18n } from 'helpers/i18n';
import { SaveOutlined } from '@ant-design/icons';
import Map from 'components/Map';
import bbox from '@turf/bbox';

const QuarterForm = ({ quarter: { id, name }, geoJsonLayers }) => {
  const mapRef = useRef(null);
  const [form] = Form.useForm();
  const action = id ? i18n.t('edit') : i18n.t('create');
  const quarterName = name ? `«${name}»` : '';
  const { quartersGeoJson } = geoJsonLayers;

  return (
    <Layout>
      <Typography.Title>
        {`${action} ${i18n.t('activerecord.models.quarter').toLocaleLowerCase()} ${quarterName}`}
      </Typography.Title>

      <Form
        form={form}
        layout="vertical"
        initialValues={{ name }}
      >
        <Form.Item
          name="shape"
          label={i18n.t('activerecord.attributes.quarter.shape')}
          rules={[{ required: true }]}
        >
          <Map
            mapRef={mapRef}
            geoJsonLayers={geoJsonLayers}
            bounds={bbox(quartersGeoJson)}
            isHoverEnabled={false}
            isClickEnabled={false}
          />
        </Form.Item>

        <Form.Item
          name="name"
          label={i18n.t('activerecord.attributes.quarter.name')}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit" type="primary">
            <SaveOutlined />
            {i18n.t('save')}
          </Button>
        </Form.Item>
      </Form>
    </Layout>
  );
};

export default QuarterForm;
