import { css } from '@emotion/css';

const formStyles = css`
  min-width: 310px;
  background-color: #fff;
  padding: 20px 20px 10px;
  border-radius: 20px;
  box-shadow: 0 0 3px #ccc;

  .ant-form-item {
    margin-bottom: 10px;
  }
`;

const iconStyles = css`
  color: rgba(0, 0, 0, 0.24);
  margin-right: 5px;
`;

export { formStyles, iconStyles };
