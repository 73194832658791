import React from 'react';
import { arrayOf, bool, node, oneOfType, string } from 'prop-types';
import { App, Layout, Col } from 'antd';
import { colorPrimary, whiteColor } from 'constants';
import useBreakpoints from 'hooks/useBreakpoints';
import AppConfigProvider from './AppConfigProvider';
import { layoutStyles } from './styles';

const getSpanAndOffset = ({ isXs, isSm, isMd, isLg }) => {
  const screenSizeColumParamsMapping = new Map([
    [true, { span: 16, offset: 4 }],
    [isXs, { span: 22, offset: 1 }],
    [isSm, { span: 20, offset: 2 }],
    [isMd, { span: 18, offset: 3 }],
    [isLg, { span: 16, offset: 4 }],
  ]);

  return screenSizeColumParamsMapping.get(true);
};

const CustomLayout = ({ className, fullWidth, children }) => {
  const breakpoints = useBreakpoints();
  const { span, offset } = getSpanAndOffset(breakpoints);

  return (
    <AppConfigProvider>
      <App>
        <Layout className={layoutStyles}>
          <Layout.Content className={className} style={{ overflow: 'scroll' }}>
            {fullWidth ? children : (
              <Col span={span} offset={offset}>
                {children}
              </Col>
            )}
          </Layout.Content>
        </Layout>
      </App>
    </AppConfigProvider>
  );
};

CustomLayout.propTypes = {
  className: string,
  fullWidth: bool,
  children: oneOfType([node, arrayOf(node)]).isRequired,
};

CustomLayout.defaultProps = {
  className: '',
  fullWidth: false,
};

export { colorPrimary, whiteColor };
export default CustomLayout;
