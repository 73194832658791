import { css } from '@emotion/css';

const layoutStyles = css`
  height: 100vh;
`;

const menuContainerStyles = css`
  background-color: #fff;
  padding-bottom: 2px;
  flex-wrap: nowrap;
  margin-bottom: 5px;

  .ant-menu {
    border-bottom: none;
  }

  .ant-menu-overflow-item::after {
    bottom: 1px !important;
  }

  .ant-menu-item {
    padding-inline: 10px;

    ::after {
      inset-inline: 10px !important;
    }
  }

  .ant-menu-submenu {
    padding-inline: 10px;
  }
`;

const leftMenuStyles = css`
  flex-grow: 1;
`;

const rightMenuStyles = css`
`;

const menuIconStyles = css`
  position: relative;
  top: 3px;
  font-size: 21px !important;
`;

export { layoutStyles, menuContainerStyles, leftMenuStyles, rightMenuStyles, menuIconStyles };
