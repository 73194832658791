import React from 'react';
import { Typography, Table } from 'antd';
import Layout from 'components/Layout';
import { i18n } from 'helpers/i18n';

const { Title } = Typography;

const ManualWorks = () => (
  <Layout>
    <Title>{i18n.t('manual_works')}</Title>

    <Table />
  </Layout>
);

export default ManualWorks;
