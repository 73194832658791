import React, { useState } from 'react';
import { arrayOf, number, shape, string } from 'prop-types';
import { Row, Tag, Typography, Space, Button, Popconfirm, Descriptions } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useMapColorType } from 'components/Map/hooks/useMapColorType';
import { quarterPath, editQuarterPath, monitoringPath } from 'js-routes';
import { snakeCase } from 'lodash';
import Layout from 'components/Layout';
import { fetchData } from 'helpers/fetchData';
import { i18n } from 'helpers/i18n';
import Map from 'components/Map';
import bbox from '@turf/bbox';

const removeQuarterRequest = (id) => fetchData({
  url: quarterPath(id),
  method: 'DELETE',
}).then(() => { window.location.href = monitoringPath(); });

const buildDescriptionValue = (key, value) => {
  if (['cultivar', 'crop'].includes(key)) {
    return value.map(({ color, name }) => <Tag key={name} color={color}>{name}</Tag>);
  }

  return value;
};

const getFeatures = (geoJsonLayers) => {
  const quartersFeatures = geoJsonLayers.quartersGeoJson?.features || [];
  const rowsFeatures = geoJsonLayers.quarterRowsGeoJson?.features || [];
  const plantsFeatures = geoJsonLayers.plantsGeoJson?.features || [];

  return [...quartersFeatures, ...rowsFeatures, ...plantsFeatures];
};

const Quarter = ({ quarterAttributes, geoJsonLayers }) => {
  const [mapInstance, setMapInstance] = useState(null);
  const { mapColorType, setMapColorType } = useMapColorType({
    mapInstance, features: getFeatures(geoJsonLayers),
  });
  const { id, name } = quarterAttributes;

  const items = Object.entries(quarterAttributes).map(([key, value]) => ({
    label: i18n.t(`activerecord.attributes.quarter.${snakeCase(key)}`),
    children: buildDescriptionValue(key, value),
  }));

  return (
    <Layout>
      <Row justify="space-between" align="middle">
        <Typography.Title>{`${i18n.t('activerecord.models.quarter')} «${name}»`}</Typography.Title>

        <Space>
          <Button href={editQuarterPath(id)} icon={<EditOutlined />}>
            {i18n.t('edit')}
          </Button>

          <Popconfirm
            title={i18n.t('quarter.delete_popup_title')}
            description={i18n.t('quarter.delete_popup_description')}
            onConfirm={() => removeQuarterRequest(id)}
          >
            <Button type="primary" icon={<DeleteOutlined />} danger>
              {i18n.t('delete')}
            </Button>
          </Popconfirm>
        </Space>
      </Row>

      <Map
        geoJsonLayers={geoJsonLayers}
        bounds={bbox(geoJsonLayers.quartersGeoJson)}
        setMapInstance={setMapInstance}
        mapColorType={mapColorType}
        setMapColorType={setMapColorType}
        isHoverEnabled={false}
        isClickEnabled={false}
      />

      <Descriptions
        items={items}
        column={1}
        contentStyle={{ display: 'block' }}
        style={{ margin: '20px 0' }}
        bordered
      />
    </Layout>
  );
};

Quarter.propTypes = {
  quarterAttributes: shape({
    id: number,
    name: string,
    rowsCount: number,
    crop: arrayOf(shape({})),
    cultivar: arrayOf(shape({})),
    area: string,
    description: string,
    createdAt: string,
    updatedAt: string,
  }).isRequired,
  geoJsonLayers: shape({
    quartersGeoJson: shape({}),
  }).isRequired,
};

export default Quarter;
