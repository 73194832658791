import React from 'react';
import { number, shape, string } from 'prop-types';
import { Card, Tag, Badge, Descriptions } from 'antd';
import { i18n, dateFormatter } from 'helpers/i18n';
import { isJSON } from 'helpers';
import { pick, snakeCase, isEmpty } from 'lodash';
import { colorPrimary } from 'constants';
import { popupStyles } from '../../styles';

const keysToShow = [
  'cultivar', 'crop', 'rootstock', 'isPollinator',
  'plantingDate', 'quarterRow', 'quarter', 'coordinates',
];

const buildDescriptionValue = (key, stringifiedValue) => {
  const value = isJSON(stringifiedValue) ? JSON.parse(stringifiedValue) : stringifiedValue;
  if (isEmpty(value)) return '—';

  if (['cultivar', 'crop'].includes(key)) {
    return value.map(({ color, name }) => <Tag key={name} color={color}>{name}</Tag>);
  }

  if (key === 'plantingDate') return dateFormatter.format(new Date(value));

  return value;
};

const PlantPopup = ({ properties }) => {
  const descriptionItems = (
    Object
      .entries(pick(properties, keysToShow))
      .map(([key, value]) => ({
        label: i18n.t(`activerecord.attributes.plant.${snakeCase(key)}`),
        children: buildDescriptionValue(key, value),
      }))
  );

  return (
    <Badge.Ribbon text={i18n.t('activerecord.models.plant')} color={colorPrimary}>
      <Card title={`#${properties.id}`} size="small" style={{ minWidth: 300 }}>
        <Descriptions className={popupStyles} items={descriptionItems} column={1} />
      </Card>
    </Badge.Ribbon>
  );
};

PlantPopup.propTypes = {
  properties: shape({
    id: number,
    cultivar: string,
    crop: string,
    rootstock: string,
    isPollinator: string,
    plantingDate: string,
    quarterRow: string,
    quarter: string,
    coordinates: string,
  }).isRequired,
};

export default PlantPopup;
