import React, { useState } from 'react';
import { Drawer, Space, Button, Tree } from 'antd';
import { FilterOutlined } from '@ant-design/icons';

const treeData = [
  {
    title: 'parent 1',
    key: '0-0',
    children: [
      {
        title: 'parent 1-0',
        key: '0-0-0',
        disabled: true,
        children: [
          {
            title: 'leaf',
            key: '0-0-0-0',
            disableCheckbox: true,
          },
          {
            title: 'leaf',
            key: '0-0-0-1',
          },
        ],
      },
      {
        title: 'parent 1-1',
        key: '0-0-1',
        children: [
          {
            title: (
              <span
                style={{
                  color: '#1677ff',
                }}
              >
                sss
              </span>
            ),
            key: '0-0-1-0',
          },
        ],
      },
    ],
  },
];

const FilterButton = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <Space>
      <Button
        icon={<FilterOutlined style={{ fontSize: 22, marginTop: 1 }} />}
        onClick={() => setIsDrawerOpen(true)}
        type="text"
      />
      <Drawer
        title="Filter"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <Tree treeData={treeData} defaultExpandAll />
      </Drawer>
    </Space>
  );
};

export default FilterButton;
