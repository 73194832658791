import BerriesIcon from './berries.svg';
import CerealsAndOilSeedsIcon from './cereals_and_oil_seeds.svg';
import CitrusIcon from './citrus.svg';
import GrapesIcon from './grapes.svg';
import NutsIcon from './nuts.svg';
import OtherIcon from './other.svg';
import PomeFruitIcon from './pome_fruit.svg';
import PulsesAndLegumesIcon from './pulses_and_legumes.svg';
import StoneFruitIcon from './stone_fruit.svg';
import SubtropicalIcon from './subtropical.svg';
import SugarIcon from './sugar.svg';
import VegetablesIcon from './vegetables.svg';

const cropTypeNameToIconName = {
  berries: BerriesIcon,
  cerealsAndOilSeeds: CerealsAndOilSeedsIcon,
  citrus: CitrusIcon,
  grapes: GrapesIcon,
  nuts: NutsIcon,
  other: OtherIcon,
  pomeFruit: PomeFruitIcon,
  pulsesAndLegumes: PulsesAndLegumesIcon,
  stoneFruit: StoneFruitIcon,
  subtropical: SubtropicalIcon,
  sugar: SugarIcon,
  vegetables: VegetablesIcon,
};

export { cropTypeNameToIconName };
