import { css } from '@emotion/css';

const mapColoringSwitcherStyles = css`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
  background-color: #fff;
  border-radius: 10px;
`;

export { mapColoringSwitcherStyles };
