import React from 'react';
import { shape, string } from 'prop-types';
import { Space } from 'antd';
import Map from 'components/Map';
import bbox from '@turf/bbox';

const shapesTypeNameToGeojsonNameMapping = {
  quarters: 'quartersGeoJson',
  quarter_rows: 'quarterRowsGeoJson',
  plants: 'plantsGeoJson',
};

const SecondStep = ({ shapesType, geoJson }) => (
  <Space direction="vertical" size={13} style={{ width: '100%' }}>
    <Map
      geoJsonLayers={{ [shapesTypeNameToGeojsonNameMapping[shapesType]]: geoJson }}
      bounds={geoJson && bbox(geoJson)}
      isHoverEnabled={false}
      isClickEnabled={false}
      style={{ height: '500px' }}
    />
  </Space>
);

SecondStep.propTypes = {
  shapesType: string.isRequired,
  geoJson: shape({}),
};

SecondStep.defaultProps = {
  geoJson: null,
};

export default SecondStep;
