import React from 'react';
import { Typography } from 'antd';
import { UploadOutlined, LogoutOutlined, GlobalOutlined, SettingOutlined } from '@ant-design/icons';
import { MapIcon, GeoPointIcon, HarvestingIcon, CropIcon, ManualWork } from 'icons';
import { fetchData } from 'helpers/fetchData';
import { omit } from 'lodash';
import { i18n } from 'helpers/i18n';
import { companyName } from 'helpers/userSession';
import {
  monitoringPath,
  scoutingReportsPath,
  manualWorksPath,
  shapefilesUploaderIndexPath,
  updateUserPath,
  destroyUserSessionPath,
  cropsPath,
  manualWorkTypesPath,
} from 'js-routes';
import { menuIconStyles } from './styles';

const { Link } = Typography;

const omitCustomData = (items) => items.map((item) => {
  const { children, ...rest } = item;

  return {
    ...omit(rest, ['urlPath', 'onClick']),
    ...(children && { children: omitCustomData(children) }),
  };
});

const updateLocale = (locale) => () => (
  fetchData({
    url: updateUserPath(),
    method: 'PUT',
    contentType: 'json',
    body: { user: { locale } },
  }).then(() => window.location.reload())
);

const locales = [
  {
    key: '1',
    locale: 'uk',
    label: '🇺🇦 Українська',
  },
  {
    key: '2',
    locale: 'ru',
    label: '🇷🇺 Русский',
  },
  {
    key: '3',
    locale: 'en',
    label: '🇺🇸 English',
  },
];

const leftMenuItems = [
  {
    key: 'l:1',
    icon: <MapIcon />,
    label: <Link href={monitoringPath()}>{i18n.t('monitoring')}</Link>,
    urlPath: monitoringPath(),
  },
  {
    key: 'l:2',
    icon: <GeoPointIcon />,
    label: <Link href={scoutingReportsPath()}>{i18n.t('scouting_reports')}</Link>,
    urlPath: scoutingReportsPath(),
  },
  {
    key: 'l:3',
    icon: <HarvestingIcon />,
    label: <Link href={manualWorksPath()}>{i18n.t('manual_works')}</Link>,
    urlPath: manualWorksPath(),
  },
];

const rightMenuItems = [
  {
    key: 'r:1',
    label: <SettingOutlined className={menuIconStyles} />,
    popupClassName: 'settings-menu-popup',
    children: [
      {
        key: 'r:1:1',
        label: companyName,
      },
      { type: 'divider' },
      {
        key: 'r:1:2',
        icon: <CropIcon />,
        label: <Link href={cropsPath()}>{i18n.t('crop_catalog.title')}</Link>,
        urlPath: cropsPath(),
      },
      {
        key: 'r:1:3',
        icon: (
          <div style={{ height: 18, width: 18 }}>
            <ManualWork />
          </div>
        ),
        label: <Link href={manualWorkTypesPath()}>{i18n.t('manual_work_type_catalog.title')}</Link>,
        urlPath: manualWorkTypesPath(),
      },
      {
        key: 'r:1:4',
        icon: <UploadOutlined />,
        label: (
          <Link href={shapefilesUploaderIndexPath()}>
            {i18n.t('shapefiles_uploader.shapefiles_uploader')}
          </Link>
        ),
        urlPath: shapefilesUploaderIndexPath(),
      },
      {
        key: 'r:1:5',
        label: i18n.t('language'),
        icon: <GlobalOutlined />,
        children: locales.map(({ locale, label, key }) => ({
          key: `r:1:4:${key}`,
          onClick: updateLocale(locale),
          label,
        })),
      },
      { type: 'divider' },
      {
        key: 'r:1:6',
        label: i18n.t('active_admin.logout'),
        icon: <LogoutOutlined />,
        danger: true,
        onClick: () => (
          fetchData({
            url: destroyUserSessionPath(),
            method: 'DELETE',
            responseType: 'text',
          }).then(() => { window.location = '/'; })
        ),
      },
    ],
  },
];

export { leftMenuItems, rightMenuItems, omitCustomData };
