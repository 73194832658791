// app/javascript/components/MenuComponent.jsx
import React from 'react';
import { Menu } from 'antd';
import AppConfigProvider from 'components/Layout/AppConfigProvider';
import {
  manualWorksPath,
  harvestManualWorksPath,
} from 'js-routes';

const { Item } = Menu;

const MenuSidebar = ({ controllerName }) => {
  const getActiveKey = () => {
    if (controllerName === 'harvest') return 'harvest';
    if (controllerName === 'payroll') return 'payroll';
    if (controllerName === 'timesheet') return 'timesheet';

    return 'overview';
  };

  return (
    <AppConfigProvider>
      <Menu mode="inline" style={{ width: 256 }} selectedKeys={[getActiveKey()]}>
        <Item key="overview">
          <a href={manualWorksPath()}>Overveiw</a>
        </Item>
        <Item key="harvest">
          <a href={harvestManualWorksPath()}>Harvest</a>
        </Item>
        <Item key="payroll">
          <a href="#">Payroll</a>
        </Item>
        <Item key="timesheet">
          < a href="#">Timesheet</a>
        </Item>
      </Menu>
    </AppConfigProvider>
  );
};

export default MenuSidebar;
