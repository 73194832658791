import React from 'react';
import { Card, Badge, Col } from 'antd';
import { workTypeNameToIconName } from 'icons/manual_work_type_groups';

const WorkTypeGroup = ({ workTypeGroup, span, onCardClick }) => {
  const workTypesCount = workTypeGroup.workTypes.length;
  const WorkTypeGroupIcon = workTypeNameToIconName[workTypeGroup.name];

  return (
    <Col
      key={workTypeGroup.id}
      onClick={() => onCardClick(workTypeGroup.id)}
      span={span}
    >
      <Card
        title={workTypeGroup.humanName}
        extra={<Badge count={workTypesCount} style={{ backgroundColor: '#999', marginLeft: 5 }} />}
        hoverable
      >
        <WorkTypeGroupIcon />
      </Card>
    </Col>
  );
};

export default WorkTypeGroup;
