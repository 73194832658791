import React from 'react';
import Layout from 'components/Layout';
import { Form, Input, Button, Typography } from 'antd';
import { i18n } from 'helpers/i18n';
import { SaveOutlined } from '@ant-design/icons';
import Map from 'components/Map';
import bbox from '@turf/bbox';

const PlantForm = ({ plant: { id }, geoJsonLayers }) => {
  const [form] = Form.useForm();
  const action = id ? i18n.t('edit') : i18n.t('create');
  const { plantsGeoJson } = geoJsonLayers;

  return (
    <Layout>
      <Typography.Title>
        {`${action} ${i18n.t('activerecord.models.plant').toLocaleLowerCase()} #${id}`}
      </Typography.Title>

      <Form
        form={form}
        layout="vertical"
      >
        <Form.Item
          name="location"
          label={i18n.t('activerecord.attributes.plant.location')}
          rules={[{ required: true }]}
        >
          <Map form={form} geoJsonLayers={geoJsonLayers} bounds={bbox(plantsGeoJson)} />
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit" type="primary">
            <SaveOutlined />
            {i18n.t('save')}
          </Button>
        </Form.Item>
      </Form>
    </Layout>
  );
};

export default PlantForm;
