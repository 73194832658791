import { arrayOf, number, shape, string } from 'prop-types';

const cropPropTypes = shape({
  id: number,
  name: string,
  humanName: string,
  color: string,
  cultivars: arrayOf(shape({
    id: number,
    name: string,
    humanName: string,
  })),
});

const cropTypePropTypes = shape({
  id: number,
  name: string,
  humanName: string,
  crops: arrayOf(cropPropTypes),
});

export { cropTypePropTypes, cropPropTypes };
