import React from 'react';
import { func, string } from 'prop-types';
import { Row, Radio } from 'antd';
import { i18n } from 'helpers/i18n';
import { mapColoringSwitcherStyles } from './styles';

const MAP_COLOR_TYPES = ['cultivar', 'crop'];
const defaultMapColorType = MAP_COLOR_TYPES[0];

const options = MAP_COLOR_TYPES.map((type) => ({
  label: i18n.t(`activerecord.models.${type}`, { count: 0 }), value: type,
}));

const MapColoringSwitcher = ({ mapColorType, setMapColorType }) => (
  <Row className={mapColoringSwitcherStyles}>
    <Radio.Group
      value={mapColorType}
      options={options}
      onChange={({ target }) => setMapColorType(target.value)}
      optionType="button"
      buttonStyle="solid"
      size="small"
    />
  </Row>
);

MapColoringSwitcher.propTypes = {
  mapColorType: string.isRequired,
  setMapColorType: func.isRequired,
};

export { defaultMapColorType };
export default MapColoringSwitcher;
